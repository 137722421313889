<br><br>
<div class="container">
  <div class="bracket forward">
    <div class="round">
      <h3>Voorronde</h3>
      <div class="match">
        <div class="team"><span class="bg-success">Co van Heerwaarden</span></div>
        <div class="team"><span>Paul Eelman</span></div>
      </div>
      <div class="match">
        <div class="team"><span>André Meerkerk</span></div>
        <div class="team"><span class="bg-success">Nanning Kikkert</span></div>
      </div>
      <div class="match">
        <div class="team"><span>Kamil Wissenburg</span></div>
        <div class="team"><span class="bg-success">Luis Fernández</span></div>
      </div>
      <div class="match">
        <div class="team"><span>Piet Bakelaar</span></div>
        <div class="team"><span class="bg-success">Jaap de Wijk</span></div>
      </div>
    </div>
    <div class="round">
      <h3>Ronde 1</h3>
      <div class="match">
        <div class="team"><span class="bg-success">Co van Heerwaarden</span></div>
        <div class="team"><span>Nanning Kikkert</span></div>
      </div>
      <div class="match">
        <div class="team"><span>Luis Fernández</span></div>
        <div class="team"><span class="bg-success">Jaap de Wijk</span>
        </div>
      </div>
    </div>
      <div class="round">
        <h3>Ronde 2</h3>
        <div class="match">
          <div class="team"><span>Co van Heerwaarden</span></div>
          <div class="team"><span  class="bg-success">Jaap de Wijk</span></div>
        </div>
      </div>
    </div>


    <div class="bracket final p-5">
      <div class="round">
        <h3>Bekerfinale</h3>
        <div class="match final">
          <div class="team"><span  class="bg-success">Jaap Dros</span></div>
          <div class="team"><span>Jaap de Wijk</span></div>
        </div>

      </div>
    </div>

    <div class="bracket reverse">
      <div class="round">
        <h3>Voorronde</h3>
        <div class="match">
          <div class="team"><span class="bg-success">Jaap Dros</span></div>
          <div class="team"><span>Jon van Dorsten</span></div>
        </div>
        <div class="match">
          <div class="team"><span class="bg-success">Pieter Duinker</span></div>
          <div class="team"><span>Gerard Postma</span>
          </div>
        </div>
        <div class="match">
          <div class="team"><span class="bg-success">Abe Zijm</span></div>
          <div class="team"><span>Manuel Dros</span></div>
        </div>
        <div class="match">
          <div class="team"><span class="bg-success">Cees Witte</span></div>
          <div class="team"><span>Taeke van Lingen</span></div>
        </div>
      </div>
      <div class="round">
        <h3>Ronde 1</h3>
        <div class="match">
          <div class="team"><span class="bg-success">Jaap Dros</span></div>
          <div class="team"><span>Pieter Duinker</span></div>
        </div>
        <div class="match">
          <div class="team"><span>Abe Zijm</span></div>
          <div class="team"><span class="bg-success">Cees Witte</span></div>
        </div>
      </div>
      <div class="round">
        <h3>Ronde 2</h3>
        <div class="match">
          <div class="team"><span  class="bg-success">Jaap Dros</span></div>
          <div class="team"><span>Cees Witte</span></div>
        </div>
      </div>
    </div>
</div>
