import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  public constructor(private titleService: Title ) { }


  ngOnInit(): void {
    this.titleService.setTitle('Schaakclub En Passant Texel');
  }

}
