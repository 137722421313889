import { Injectable, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthserviceService {
  isAuthenticated = false;
  isAdmin = false;

  constructor(private auth: AuthService) {
    if (!localStorage.getItem('acces_token'))
    {
     try {
      this.auth.getAccessTokenSilently({
        authorizationParams: {
        audience: 'https://enpassant/API',
        ignoreCache: false
        }
      }).subscribe((token) => {
        localStorage.setItem('access_token', token);
        this.SetAuthenticated(token);
      }, (err) => {
        //ignore
      });
     }
     catch
     {
        this.isAdmin = false;
        this.isAuthenticated = false;
     }
    }
  }

  private SetAuthenticated(token: string) {
    try {
      const helper = new JwtHelperService();
      const decoded = helper.decodeToken(token);
      this.isAdmin = decoded.sub === 'auth0|5fa825d0802e6600681a7672';
      this.isAuthenticated = true;
    }
    catch {
      this.isAuthenticated = false;
    }
  }

  public logout()
  {
    try {
      localStorage.removeItem('access_token');
      this.auth.logout();
      this.isAuthenticated = false;
      this.isAdmin = false;
    } catch
    {
      this.isAuthenticated = false;
      this.isAdmin = false;
    }
  }
  public login()
  {
    try {
      this.auth.loginWithRedirect({
        authorizationParams: {
        audience: 'https://enpassant/API',
        redirect_uri: 'https://' + window.location.host,
        language: 'nl'},
        });
    }
    catch {
      this.isAuthenticated = false;
      this.isAdmin = false;
    }
  }
}
