import { NgpImagePickerModule } from 'ngp-image-picker';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgendaComponent } from './agenda/agenda.component';
import { HomeComponent } from './home/home.component';
import { Router, RouterModule } from '@angular/router';
import { AuthModule } from '@auth0/auth0-angular';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/extra/nl';
import { LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { nlLocale } from 'ngx-bootstrap/locale';
import { ArtikelenComponent } from './artikelen/artikelen.component';
import { PrivacystatementComponent } from './privacystatement/privacystatement.component';
import { CompetitieStandComponent } from './competitie-stand/competitie-stand.component';
import { CompetitieSpelerComponent } from './competitie-speler/competitie-speler.component';
import { CompetitieSpeeldagComponent } from './competitie-speeldag/competitie-speeldag.component';
import { WedstrijdentabelComponent } from './wedstrijdentabel/wedstrijdentabel.component';
import { NhsbComponent } from './nhsb/nhsb.component';
import { BekerwedstrijdenComponent } from './bekerwedstrijden/bekerwedstrijden.component';
import { HttpClientModule } from '@angular/common/http';
import { YearselectorComponent } from './yearselector/yearselector.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { OrderByPipe } from './pipes/order-by-pipe';


registerLocaleData(localeNl, 'nl-NL', localeNlExtra);
defineLocale('nl', nlLocale);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AgendaComponent,
    ArtikelenComponent,
    PrivacystatementComponent,
    CompetitieStandComponent,
    CompetitieSpelerComponent,
    CompetitieSpeeldagComponent,
    WedstrijdentabelComponent,
    NhsbComponent,
    BekerwedstrijdenComponent,
    YearselectorComponent,
    SanitizeHtmlPipe,
    OrderByPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    AngularEditorModule,
    NgpImagePickerModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    AuthModule.forRoot({
      domain: 'enpassant.eu.auth0.com',
      clientId: '8VQAIWOvWLHX7x9vYqR7JaWR1Jdn7uht'
    })
  ],
  exports: [AngularEditorModule],
  providers: [Title, { provide: LOCALE_ID, useValue: 'nl-NL' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
