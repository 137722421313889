import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, AsyncValidatorFn, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';

type PropertyFn<T, R> = (val: T) => R;

export interface AbstractControlTyped<T> extends AbstractControl {
    readonly value: T;
    readonly valueChanges: Observable<T>;
    setValue(value: T, options?: object): T;
    patchValue(value: T, options?: object): T;
    reset(value?: T, options?: object): void;
    get(path: Array<string | number> | string): AbstractControl | null;
    get<R>(path: Array<string | number> | string): AbstractControlTyped<R> | null;
    getSafe<R>(propertyFn: PropertyFn<T, R>): AbstractControlTyped<R> | null;
}

export interface FormGroupTyped<T> extends FormGroup {
    readonly value: T;
    readonly valueChanges: Observable<T>;
    setValue(value: T, options?: object): T;
    patchValue(value: T, options?: object): T;
    reset(value?: T, options?: object): void;
    get(path: Array<string | number> | string): AbstractControl | null;
    get<V>(path: Array<string | number> | string): AbstractControlTyped<V> | null;
    getSafe<R>(propertyFn: PropertyFn<T, R>): AbstractControlTyped<R> | null;

    registerControl(name: string, control: AbstractControl): AbstractControl;
    registerControl<V>(name: string, control: AbstractControl): AbstractControlTyped<V>;
    registerControlSafe<R>(propertyFn: PropertyFn<T, R>, control: AbstractControl): AbstractControlTyped<R>;

    addControlSafe<R>(propertyFn: PropertyFn<T, R>, control: AbstractControl): void;
    removeControlSafe<R>(propertyFn: PropertyFn<T, R>): void;
    setControlSafe<R>(propertyFn: PropertyFn<T, R>, control: AbstractControl): void;
}

export type ControlsConfigTyped<T> = {
    [P in keyof T]: FormControl | FormGroup | FormArray | undefined[] | {
        0?: T[P];
        1?: ValidatorFn | ValidatorFn[];
        2?: AsyncValidatorFn | AsyncValidatorFn[];
    };
};

@Injectable({
    providedIn: 'root'
})
export class FormBuilderTyped extends FormBuilder {
    private static getPropertyName(propertyFunction: PropertyFn<any, any>): string {
        let properties: string[];
        if (propertyFunction.toString().indexOf('=>') !== -1) {
            properties = propertyFunction.toString().split('=>')[1].trim()
                .split('.')
                .splice(1);
        } else {
            properties = propertyFunction.toString()
                .match(/(?![. ])([a-z0-9_]+)(?=[};.])/gi)
                .splice(1);
        }
        return properties.join('.');
    }

    private static getSafe<T, R>(group: AbstractControl, propertyFn: PropertyFn<T, R>): AbstractControlTyped<R> {
        const getStr = FormBuilderTyped.getPropertyName(propertyFn);
        return this.mapControl(group.get(getStr) as AbstractControlTyped<R>);
    }

    private static registerControlSafe<T, R>(group: FormGroup, propertyFn: PropertyFn<T, R>, control: AbstractControl) {
        const getStr = FormBuilderTyped.getPropertyName(propertyFn);
        return this.mapControl(group.registerControl(getStr, control) as AbstractControlTyped<R>);
    }

    private static addControlSafe<T, R>(group: FormGroup, propertyFn: PropertyFn<T, R>, control: AbstractControl) {
        const getStr = FormBuilderTyped.getPropertyName(propertyFn);
        group.addControl(getStr, control);
    }

    private static removeControlSafe<T, R>(group: FormGroup, propertyFn: PropertyFn<T, R>) {
        const getStr = FormBuilderTyped.getPropertyName(propertyFn);
        group.removeControl(getStr);
    }

    private static setControlSafe<T, R>(group: FormGroup, propertyFn: PropertyFn<T, R>, control: AbstractControl) {
        const getStr = FormBuilderTyped.getPropertyName(propertyFn);
        group.setControl(getStr, control);
    }

    private static mapFormGroup<T>(group: FormGroupTyped<T>): FormGroupTyped<T> {
        group.registerControlSafe = <V, R>(propertyFn: PropertyFn<V, R>, control: AbstractControl) => {
            return FormBuilderTyped.registerControlSafe(group, propertyFn, control);
        };

        group.addControlSafe = <V, R>(propertyFn: PropertyFn<V, R>, control: AbstractControl) => {
            FormBuilderTyped.addControlSafe(group, propertyFn, control);
        };

        group.removeControlSafe = <V, R>(propertyFn: PropertyFn<V, R>) => {
            FormBuilderTyped.removeControlSafe(group, propertyFn);
        };

        group.setControlSafe = <V, R>(propertyFn: PropertyFn<V, R>, control: AbstractControl) => {
            return FormBuilderTyped.setControlSafe(group, propertyFn, control);
        };

        return group;
    }

    private static mapControl<T>(control: FormGroupTyped<T>): FormGroupTyped<T>;
    private static mapControl<T>(control: AbstractControlTyped<T>): AbstractControlTyped<T>;
    private static mapControl<T>(control: AbstractControlTyped<T> | FormGroupTyped<T>): AbstractControlTyped<T> {
        control.getSafe = <V, R>(propertyFn: PropertyFn<V, R>) => {
            return FormBuilderTyped.getSafe(control, propertyFn);
        };

        if (control instanceof FormGroup) {
            this.mapFormGroup(control);
        }

        return control;
    }

    group(controlsConfig: ControlsConfigTyped<any>, extra?: { [key: string]: any; } | null): FormGroup;
    group<T>(controlsConfig: ControlsConfigTyped<T>, extra?: { [key: string]: any; } | null): FormGroupTyped<T>;
    group<T>(controlsConfig: ControlsConfigTyped<T>, extra?: { [key: string]: any; } | null): FormGroupTyped<T> {
        const group = super.group(controlsConfig, extra) as FormGroupTyped<T>;

        if (group) {
            return FormBuilderTyped.mapControl(group);
        }
    }
}
