<section   *ngIf="competitie">
  <div class="container">
    <div class="row">

      <div class="col-md-9 table-responsive">
        <h2>{{round.Name}} op {{round.Date}}</h2>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" width="50%">Wit</th>
            <th scope="col" width="50%">Zwart</th>
            <th scope="col" width="10%" class="text-center">Uitslag</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let roundgame of getRound()">
              <td><a routerLink='/Competitiespeler/{{ roundgame.WitId }}'>{{ roundgame.Wit }}</a></td>
              <td><a routerLink='/Competitiespeler/{{ roundgame.ZwartId }}'>{{ roundgame.Zwart }}</a> </td>
              <td class="text-center">{{ roundgame.Resultaat }}</td>
          </tr>
        </tbody>
      </table>
      </div>
      <div class="col-sm-3">
        <h3>Ronde's</h3>
        <u class="list-group">
          <li class="list-group-item list-group-item-action" *ngFor="let round of competitie.GroupReport[0].RoundHist| orderBy: 'ID' : true : false"><a routerLink='/Competitiespeeldag/{{round.ID}}'>{{round.Name}} op {{round.Date}}</a></li>
        </u>
      </div>
    </div>
    <div class="row pt-3 pb-3">
      <app-yearselector></app-yearselector>
    </div>
  </div>
</section>
