<ng-template #content let-modal>
  <form *ngIf="agendaForm" name="reservationForm" class="login-form" [formGroup]="agendaForm" novalidate>

    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Agenda wijzigen</h4>
    </div>
    <div class="modal-body">
      <div class="form-group row">
        <label class="col-form-label col-sm-4" for="eventdate">Datum</label>
        <div class="col-sm-8">
          <div class="input-group">
            <input type="text" id="eventdate" bsDatepicker [bsConfig]="{
              isAnimated: true,
              showWeekNumbers: false
            }" placeholder="" class="form-control form-control-datepicker" formControlName="eventdate" />
            <span class="input-group-addon"> </span>
            <timepicker [showMeridian]="false" [showSpinners]="false" id="eventtime" placeholder=""
              formControlName="eventtime"></timepicker>
          </div>
        </div>
      </div>
        <div class="form-group row">
          <label class="col-form-label col-sm-4" for="thema">Thema</label>
          <div class="col-sm-8">
            <input type="text" id="thema" placeholder="" class="form-control" formControlName="thema" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-sm-4" for="locatie">Locatie</label>
          <div class="col-sm-8">
            <input type="text" id="locatie" placeholder="" class="form-control" formControlName="locatie" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-sm-4" for="opmerkingen">Opmerkingen</label>
          <div class="col-sm-8">
            <input type="text" id="opmerkingen" placeholder="" class="form-control" formControlName="opmerkingen" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Close')">Sluiten</button>
        <button type="button" class="btn btn-primary" (click)="modal.close('Save')">Opslaan</button>
      </div>

  </form>
</ng-template>
<section>
  <div class="container">
    <div class="row table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Datum</th>
            <th scope="col">Tijd</th>
            <th scope="col">Thema</th>
            <th scope="col">Locatie</th>
            <th scope="col">Opmerkingen</th>
            <th scope="col" *ngIf="auth.isAdmin"></th>
            <th scope="col" *ngIf="auth.isAdmin"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of Agenda">
            <td>{{ item.EventDate | date: "EEEEEE dd LLL yyyy" }}</td>
            <td>{{ item.EventDate | date: "HH:mm" }}</td>
            <td>{{ item.Theme }}</td>
            <td>{{ item.Location }}</td>
            <td>{{ item.Description }}</td>
            <td *ngIf="auth.isAdmin">
              <button type="button" class="btn btn-primary" (click)="open(content, item)">
                <i class="bx bx-edit"></i>
              </button>
            </td>
            <td *ngIf="auth.isAdmin">
              <button type="button" class="btn btn-danger" (click)="delete(item)">
                <i class="bx bx-x-circle"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="loading" ><div class="spinner-border" style="width: 3rem; height: 3rem;" role="status" >
    </div>
    <span> Laden...</span>
  </div>

    <div class="row" *ngIf="auth.isAdmin">
      <button type="button" class="btn btn-primary" (click)="open(content, null)">
        <i class="bx bx-new"></i> Nieuw
      </button>
    </div>
  </div>
</section>
