<!-- <button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button> -->
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top" (mouseleave)="isCollapsed = false" >
    <div class="container d-flex align-items-center">

      <h1 class="logo mr-auto"><a routerLink="/Home"><img src="assets/img/logo.webp" alt="" class="img-fluid mr-3" />En Passant <span>Texel</span></a></h1>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a routerLink="/Home" class="logo mr-auto"></a> -->
      <nav class="nav-menu navbar-expand-md">
        <button class="navbar-toggler mobile-nav-toggle" type="button" (click)="isCollapsed = !isCollapsed">
          <i class="icofont-navigation-menu"></i>
        </button>
        <div class="collapse navbar-collapse" [ngbCollapse]="!isCollapsed">
          <ul class="navbar-nav">
            <li routerLinkActive="active" (click)="isCollapsed = false"><a class="nav-link" routerLink="/Home">Home</a></li>
            <li routerLinkActive="active" (click)="isCollapsed = false"><a class="nav-link" routerLink="/Agenda">Agenda</a></li>
            <li ngbDropdown class="nav-item" routerLinkActive="active" #myDrop="ngbDropdown">
              <a class="nav-link" id="navbarDropdownMenuLink" ngbDropdownToggle aria-haspopup="true" aria-expanded="false" href="#" onclick="return false;" role="button">
                Competitie
              </a>
              <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink"  autoClose="true">
                <li routerLinkActive="active" class="nav-item" (click)="isCollapsed = false;myDrop.close()"><a class="nav-link" routerLink="/Competitiestand">Stand</a></li>
                <li routerLinkActive="active" class="nav-item" (click)="isCollapsed = false;myDrop.close()"><a class="nav-link" routerLink="/Competitiespeeldag">Uitslagen/speeldag</a></li>
                <li routerLinkActive="active" class="nav-item" (click)="isCollapsed = false;myDrop.close()"><a class="nav-link" routerLink="/Wedstrijdentabel">Wedstrijdentabel</a></li>
                <!-- <li routerLinkActive="active" class="nav-item" (click)="isCollapsed = false;myDrop.close()"><a class="nav-link" routerLink="/Bekerwedstrijden">Bekerwedstrijden</a></li> -->
                <div class="dropdown-divider"></div>
                <li routerLinkActive="active" class="nav-item" (click)="isCollapsed = false;myDrop.close()"><a class="nav-link" routerLink="/Nhsb/Rating">NHSB Ratings</a></li>
                <li routerLinkActive="active" class="nav-item" (click)="isCollapsed = false;myDrop.close()"><a class="nav-link" routerLink="/Nhsb/EersteKlasse">NHSB Eerste klasse</a></li>
                <li routerLinkActive="active" class="nav-item" (click)="isCollapsed = false;myDrop.close()"><a class="nav-link" routerLink="/Nhsb/Viertallen">NHSB Viertallen</a></li>
              </div>
            </li>
            <li class="nav-item" routerLinkActive="active" (click)="isCollapsed = false"><a class="nav-link" routerLink="/Artikelen">Artikelen</a></li>
            <li class="nav-item" routerLinkActive="active" (click)="isCollapsed = false"><a class="nav-link" routerLink="/Columns">Columns (Jaap Dros)</a></li>
            <div class="header-social-links" (click)="isCollapsed = false">


              <button *ngIf="auth.isAuthenticated" class="btn btn-sm btn-outline-primary" (click)="auth.logout()"><i class="icofont-logout"></i> Uitloggen</button>
              <button *ngIf="!auth.isAuthenticated" class="btn btn-sm btn-outline-primary" (click)="auth.login()"><i class="icofont-login"></i> Inloggen</button>



            </div>
            </ul>
          </div>
      </nav>



    </div>
  </header><!-- End Header -->



  <main id="main" role="main"  >
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-bottom">

          <h2>{{titleService.getTitle()}}</h2>

        </div>

      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= About Us Section ======= -->
    <section class="p-0">
      <div class="container" >
        <div class="row content mt-0">
          <div class="col-lg-12 entries">
            <router-outlet></router-outlet>
          </div>

        </div>

      </div>
    </section><!-- End About Us Section -->


  </main><!-- End #main -->
 <!-- ======= Footer ======= -->
 <footer id="footer">

  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-9 col-md-6 footer-contact">
          <h3>En Passant Texel</h3>
          <TABLE style="width: 100%;">
            <TR><TD VALIGN=TOP><A HREF=mailto:voorzitter@enpassant-texel.nl CLASS=text>Jon van Dorsten</A></TD>
                <TD VALIGN=TOP>Voorzitter &amp; Wedstrijdleider (Interne competitie)</TD>
                <TD VALIGN=TOP></TD></TR>
            <TR><TD VALIGN=TOP><A HREF=mailto:secretaris@enpassant-texel.nl CLASS=text>Gerard Postma</A></TD>
                <TD VALIGN=TOP>Secretaris</TD>
                <TD VALIGN=TOP>06-57967880</TD></TR>
            <TR><TD VALIGN=TOP><A HREF=mailto:penningmeester@enpassant-texel.nl CLASS=text>Gerard Snoeijs</A></TD>
                <TD VALIGN=TOP>Penningmeester</TD>
                <TD VALIGN=TOP></TD></TR>
                <TR><TD VALIGN=TOP>Jaap de Wijk</TD>
                  <TD VALIGN=TOP> Wedstrijdleider (NHSB)</TD>
                  <TD VALIGN=TOP></TD></TR>
            </TABLE>
        </div>


        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Historisch</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="https://www.enpassant-texel.nl/enpassantarchief/index.php?yr=2020&ep=410&ix=16" target="_blank">EPPIE</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="https://www.enpassant-texel.nl/enpassantarchief/index.php?yr=2020&ep=6&ix=8" target="_blank">Archief</a></li>

          </ul>
        </div>


      </div>
    </div>
  </div>

  <div class="container d-md-flex py-4">

    <div class="mr-md-auto text-center text-md-left">
      <div class="copyright">
        &copy; Copyright <strong><span>En Passant Texel</span></strong>.
      </div>
    </div>
    <div class="float-right">
      <i class="bx bx-id-card"></i> <a routerLink="/Privacystatement">Privacy statement</a>
    </div>
    <!-- <div class="social-links text-center text-md-right pt-3 pt-md-0">
      <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
      <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
      <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
      <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
      <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
    </div> -->
  </div>
</footer><!-- End Footer -->


