import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-matoptwad',
  templateUrl: './matoptwad.component.html',
  styleUrls: ['./matoptwad.component.less']
})
export class MatoptwadComponent implements OnInit {

  constructor(public sanitizer: DomSanitizer, private titleService: Title) { }
  urlSafe: SafeResourceUrl;
  ngOnInit(): void {
    this.titleService.setTitle('Inschrijven Mat op het Wad');
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://forms.office.com/Pages/ResponsePage.aspx?id=k6zvgJqLaE2538e1MQUsrRMw4VGcKVtNtWIsUOBrJihUNlJES0VWVTYzT1c5QVFHMUdHR05LWTJYWS4u');
  }

}
