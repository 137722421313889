import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Article, ArticleItem, DeleteArticleItemRequest, FetchAllArticleByIdRequest, FetchAllArticleItemsRequest, FetchAllArticleListRequest, UpdateArticleItemRequest } from './../../../enpassantapi.dtos';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FormBuilderTyped, FormGroupTyped } from '../services/form-builder-typed';
import { ApiClientProviderService } from '../services/api.client.provider.service';
import { AuthserviceService } from '../services/authservice.service';
import { FormControl, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { ImagePickerConf, NgpImagePickerModule } from 'ngp-image-picker';

interface IArticle
{
  date: Date;
  author: string;
  intro: string;
  title: string;
  imagename: string;
  article: string;
}
@Component({
  selector: 'app-artikelen',
  templateUrl: './artikelen.component.html',
  styleUrls: ['./artikelen.component.less']
})
export class ArtikelenComponent implements OnInit, OnDestroy  {

  private sub: any;
  private sub2: any;
  public editItem: Article;
  public currentarticle: Article;
  public articleimage: string;
  public loading: boolean;
  public isColumn: boolean;
  public BaseRoute: string;
  public Articles: Article[];
  public ArticleList: ArticleItem[];

  closeResult: string;
  imagePickerConf: ImagePickerConf = {
    borderRadius: '4px',
    language: 'nl',
    width: '320px',
    height: '240px',
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };

  articleForm: FormGroupTyped<IArticle>;

  constructor(private titleService: Title,
              private  apiservice: ApiClientProviderService,
              public auth: AuthserviceService,
              private formBuilderTyped: FormBuilderTyped,
              private modalService: NgbModal,
              private cdr: ChangeDetectorRef,
              private route: ActivatedRoute) {

  }

   ngOnInit() {
    this.sub2 = this.route
    .data
    .subscribe(v => {
      this.isColumn = v.IsColumn;
      if (v.IsColumn)
      {
        this.titleService.setTitle('Columns door Jaap Dros');
        this.BaseRoute = 'Columns';
      }
      else {
        this.titleService.setTitle('Artikelen');
        this.BaseRoute = 'Artikelen';
      }
    });

  this.sub = this.route.params.subscribe(async params => {
    if (params.id) {
      this.currentarticle = await this.apiservice.enpassantApi.post(new FetchAllArticleByIdRequest({
        Id: params.id
      }));
    } else {
      this.currentarticle = null;
    }
  });

    this.loadArticles();
    this.getArticleList();
  }

  public async getArticleList() {
    this.loading = true;
    try {
      if (this.ArticleList == null)
      {
      this.ArticleList = await this.apiservice.enpassantApi.post(new FetchAllArticleListRequest({
        IsColumn: this.isColumn
      }));
    }
    }
    finally {
      this.loading = false;
    }
  }
   async loadArticles() {

    this.loading = true;
    try {
      this.Articles = await this.apiservice.enpassantApi.post(new FetchAllArticleItemsRequest({
        Page:0,
        IsColumn: this.isColumn
      }));
    }
    finally {
      this.loading = false;
    }


   }
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.sub2.unsubscribe();
  }
  async submitArticle()
  {
    const request = new UpdateArticleItemRequest();
    this.editItem.Date = (dayjs(this.articleForm.controls.date.value)).format('YYYY-MM-DD');
    this.editItem.Author = this.articleForm.controls.author.value;
    this.editItem.Title = this.articleForm.controls.title.value;
    this.editItem.IsColumn = this.isColumn;
    this.editItem.ArticleIntro = this.articleForm.controls.intro.value;
    this.editItem.ArticleText = this.articleForm.controls.article.value;
    request.Article = this.editItem;
    request.ImageBase64 = this.articleimage;
    request.ImageName = this.articleForm.controls.imagename.value;
    console.log(request);
    await this.apiservice.enpassantApi.post(request);
    this.loadArticles();
  }
  setArticle(item: Article)
  {
    this.currentarticle = item;
  }
  onImageChange(event){
    this.articleimage = event;
  }

  async delete(item: Article)
  {
    const request = new DeleteArticleItemRequest();
    request.Article = item;
    await this.apiservice.enpassantApi.post(request);
    this.Articles = await this.apiservice.enpassantApi.post(new FetchAllArticleItemsRequest());
  }
  async open(content, item: Article) {
    this.editItem = item;
    if (item === null)
    {
      this.editItem = new Article();
    }

    this.articleForm = this.formBuilderTyped.group<IArticle>({
      date: new FormControl(dayjs(this.editItem.Date).toDate()),
      author: new FormControl(this.editItem.Author, Validators.maxLength(50)),
      title: new FormControl(this.editItem.Title, Validators.maxLength(50)),
      imagename: new FormControl(this.editItem.ArticleImage, Validators.maxLength(50)),
      intro: new FormControl(this.editItem.ArticleIntro, Validators.maxLength(512)),
      article: new FormControl(this.editItem.ArticleText)
    });
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',
                                     windowClass : 'articleform'}).result.then(async (result) => {
      this.closeResult = result;
      if (result === 'Save')
      {
        this.submitArticle();
      }
    }, (reason) => {
      this.closeResult = reason;
    });
  }
}
