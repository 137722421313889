import { CompetitieService } from './../services/competitie.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yearselector',
  templateUrl: './yearselector.component.html',
  styleUrls: ['./yearselector.component.less']
})
export class YearselectorComponent implements OnInit {
  public currentyear: string;
  constructor(private competitieService: CompetitieService) { }

  ngOnInit(): void {
    this.setCurrentYear();
  }

  private setCurrentYear() {
    if (localStorage.getItem('currentyear')) {
      this.currentyear = localStorage.getItem('currentyear');
    }
    else {
      this.currentyear = 'Huidig jaar';
    }
  }

  public setYear(year: string) {
    localStorage.setItem('currentyear', year);
    this.setCurrentYear();
    this.competitieService.notify();
  }
}
