import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AuthserviceService } from './services/authservice.service';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  public isCollapsed: boolean;

  public constructor(public titleService: Title,
                     private dateservice: BsLocaleService,
                     public auth: AuthserviceService,
                     private cdRef: ChangeDetectorRef,
                     private router: Router) {
              this.dateservice.use('nl');
              this.router.events.subscribe(x => {
                if(x instanceof NavigationEnd)
                {
                  window.scrollTo(0, 0);
                }
              });

  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

}

