import { Competitie, PairXTbl, PlayerXTbl } from '../competitie';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CompetitieService } from '../services/competitie.service';

@Component({
  selector: 'app-wedstrijdentabel',
  templateUrl: './wedstrijdentabel.component.html',
  styleUrls: ['./wedstrijdentabel.component.less']
})
export class WedstrijdentabelComponent implements OnInit {

  public competitie: Competitie;

  constructor(private titleService: Title,
              private competitieService: CompetitieService) {
    this.titleService.setTitle('Wedstrijdentabel');
  }

  ngOnInit() {
     this.newMethod();
  }

  private async newMethod() {
    this.competitie = await this.competitieService.getCompetitie();
    this.competitieService.notifyEvent.subscribe(async () => {
      this.competitie = await this.competitieService.getCompetitie();
    });
  }

  public GetXRef(): XrefInstance[]
  {
    const result: XrefInstance[] = [];
    this.competitie.GroupReport[0].XTbl.PlayerXTbl.forEach(ref => {
      const item = new XrefInstance();
      item.PlayerNr = ref.PlayerNr;
      item.PlayerName = this.competitie.GroupReport[0].Players.Player.find(p => p.ID === ref.PlayerNr).Name;
      item.PairXTbl = [];

      if (ref.PairXTbl.findIndex(p => p.OpponentNr === item.PlayerNr) === - 1)
      {
        ref.PairXTbl.splice((item.PlayerNr - 1), 0, {OpponentNr: item.PlayerNr, Res: 'X', Pref: ''});
      }
      ref.PairXTbl.forEach(pair => {
        const xpair: PairXTbl = {OpponentNr: pair.OpponentNr, Res: pair.Res.replace('�', '½'), Pref: pair.Pref};
        item.PairXTbl.push(xpair);
      });
      result.push(item);
    });


    return result;
  }
}

export class XrefInstance implements PlayerXTbl{
  PlayerNr: number;
  FloatInfo: string;
  PlayerName: string;
  PairXTbl: PairXTbl[];

}
