import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bekerwedstrijden',
  templateUrl: './bekerwedstrijden.component.html',
  styleUrls: ['./bekerwedstrijden.component.less']
})
export class BekerwedstrijdenComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle('Stand Bekerwedstrijden');
  }

  ngOnInit(): void {
  }

}
