<section  *ngIf="competitie" >
  <div class="container">
    <h3>Wedstrijdentabel</h3>
    <div class="row table-responsive">
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col" width="5%">#</th>
            <th scope="col" >Naam</th>
            <!-- <th scope="col" >{{competitie.Group[0].XRef[0].PlayerNr}}</th> -->
            <th scope="col" class="text-center" *ngFor="let item of competitie.GroupReport[0].XTbl.PlayerXTbl[0].PairXTbl" width="5%" >{{item.OpponentNr}}</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let xref of GetXRef()">
            <td><b>{{xref.PlayerNr}}</b></td>
            <td><a routerLink='/Competitiespeler/{{ xref.PlayerNr }}'>{{ xref.PlayerName }}</a></td>
            <td class="text-center" [ngClass]="{'bg-dark': item.Res === 'X' }" *ngFor="let item of xref.PairXTbl" width="5%" >{{item.Res}}</td>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="row pt-3 pb-3">
      <app-yearselector></app-yearselector>
    </div>
  </div>
</section>
