<section>
  <div class="container">
    <div class="entry-content">
      <img src="./assets/img/db06baba-cc48-4af8-8af2-ebbac1add7c0_thumb840.jpg"
        class="rounded float-right .img-fluid. shadow m-3" style="height: auto;max-width: 50%;"
        alt="Jaap Dros in aktie tegen Kees de Best"><img>
      <span class="alert alert-success" role="alert">
        <b>Inschrijving Mat op 't Wad geopend!</b>
      </span><br><br>
      Inschrijven voor Mat op 't Wad! kan nu<br>
      <p><a routerLink="/MatOptWad">Inschrijven voor Mat op 't Wad</a></p>
      Contactpersoon: <a href="mailto:janwillem.duijzer@gmail.com">Jan Willen Duijzer (janwillem.duijzer@gmail.com)</a>
      <p></p>
      <hr>

      <A NAME=SPEELAVOND></A>
      <b>Speelavond</b><br>
      Er wordt gespeeld op de maandagavonden (<font color=red>19:30-24:00</font>) in "De Buureton", Beatrixlaan 43,
      &nbsp; Den Burg,

      <p></p>
    </div>
    <div class="entry-content">
      <A NAME=JEUGD></A>
      <b>Jeugd</b><br>
      Neem contact op met de <A HREF=mailto:secretaris@enpassant-texel.nl CLASS=text>secretaris</A>.
      <p></p>
    </div>
    <div class="entry-content">
      <A NAME=CONTRIBUTIE></A>
      <b>Contributie</b><br>
      &euro; 95.00, Banknummer: <B>NL&nbsp;29&nbsp;RABO&nbsp;0162&nbsp;342&nbsp;128</B>, t.n.v. Schaakclub En Passant,
      Den Burg
      <p></p>
    </div>
    <p></p>
    <div class="entry-content">
      <A NAME=BOND></A>
      <b>Bond</b><br>
      Het 8-tal van En Passant speelt dit seizoen in de 1e klasse A van de Noordhollandse Schaakbond.
      <BR>Deze wedstrijden worden gespeeld op de zaterdagmiddagen.
      <p></p>
    </div>
    <br>


    <br>
    <div class="entry-content">
      <A NAME=INTC></A>
      <b>Interne competitie</b><br>
      De interne competitie wordt gespeeld middels het Keizer systeem.
      <br>Speeltijd 100 minuten plus 10 seconden per zet. (in overleg met tegenstander korter mogelijk (minimaal 45
      minuten)).
      <br>De partijen voor de bekerwedstrijden worden meegerekend met de interne competitie. De uitslag van de
      bekerwedstrijd wordt voor de interne competite bij de eerstvolgende clubavond meegeteld.
      <p></p>
    </div>

    <div class="entry-content">
      <A NAME=BEKER></A>
      <b>Bekerwedstrijden</b><br>
      Een bekerwedstrijd wordt gespeeld in combinatie met (maar niet noodzakelijk) de interne competitie.
      <BR>Aan de bekerwedstrijd kunnen alle leden van de club deelnemen. De (eerste) indeling is willekeurig
      (computerselectie) en zodanig dat er na de eerste speelronde een juist aantal spelers overblijft (16/8/4/2) om de
      strijd op de 64 velden aan te kunnen gaan tot dat er 1 winnaar overblijft.
      <br>De winnaar van elke match (men speelt 1 partij, kleur door aantal keren zwart/wit in de interne competitie en
      anders loting) gaat door naar de volgende ronde. Bij gelijke stand wordt een (snelschaak, 5 minuten per speler)
      barrage gespeeld om de uiteindelijke winnaar te bepalen.
      <p></p>
    </div>

  </div>
</section>