<section *ngIf="competitie" >
  <div class="container">
    <h3>Stand na {{competitie.GroupReport[0].Ranking[activeIndex].Name}} - {{competitie.GroupReport[0].Ranking[activeIndex].Date}}</h3>
    <div class="row table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" width="5%" class="text-center">Pos.</th>
            <th scope="col" width="70%">Naam</th>
            <th scope="col" width="10%" class="text-right">Score</th>
            <th scope="col" width="5%" class="text-right">%</th>
            <th scope="col" width="5%" class="text-right">Punten</th>
            <th scope="col" width="5%" class="text-right">Wedstr.</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let speler of competitie.GroupReport[0].Ranking[activeIndex].Player">
            <td class="font-weight-bold text-center">{{ speler.Pos }}</td>
            <td><a routerLink='/Competitiespeler/{{ speler.ID }}'>{{ speler.Name }}</a></td>
            <td class="font-weight-bold text-right">{{ speler.Score | number:'1.0-0' }}</td>
            <td class="text-right">{{ speler.Perc | number:'1.0-0' }}</td>
            <td class="text-right">{{ speler.Wins + (speler.Draws / 2) }}</td>
            <td class="text-right">{{ speler.Games }}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <u class="list-group list-group-horizontal-lg">
          <li class="list-group-item" *ngFor="let ranking of competitie.GroupReport[0].Ranking; index as i"><a routerLink='/Competitiestand/{{i}}'>{{ranking.Date}}</a></li>
        </u>
      </div>
    </div>
    <div class="row pt-3 pb-3">
      <app-yearselector></app-yearselector>
    </div>
  </div>
</section>
