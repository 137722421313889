<div ngbDropdown class="dropdown">
  Kies jaar: <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" ngbDropdownToggle
    aria-haspopup="true" aria-expanded="false">
    {{currentyear}}
  </button>
  <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" [routerLink]="" (click)="setYear('')" ngbDropdownItem>Huidig jaar</a>
    <a class="dropdown-item" [routerLink]="" (click)="setYear('2023')" ngbDropdownItem>2023 - 2024</a>
    <a class="dropdown-item" [routerLink]="" (click)="setYear('2022')" ngbDropdownItem>2022 - 2023</a>
    <a class="dropdown-item" [routerLink]="" (click)="setYear('2021')" ngbDropdownItem>2021 - 2022</a>
  </div>
</div>
