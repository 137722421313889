<section>
  <div class="container">
    <div class="entry-content" >
        <b>1. Introductie</b><br>
        De Schaakclub En Passant (de Vereniging) is de beheerder van de website enpassant-texel.nl. In dit overzicht wordt aangegeven hoe de Vereniging persoonsgegevens verzamelt en op welke wijze deze gegevens worden gebruikt. De verantwoordelijke partij voor de verwerking is de Vereniging.
      De Vereniging zet zich in voor de bescherming van de privacy van haar leden en de bezoekers van haar website. Persoonsgegevens van leden en bezoekers worden uiterst zorgvuldig verwerkt en beveiligd. De Vereniging houdt zich aan de wet en regelgeving op het gebied van de bescherming van persoonsgegevens, zoals de Wet bescherming persoonsgegevens en – met ingang van 25 mei 2018 – de Algemene Verordening Gegevensbescherming (AVG).
      <p></p>
    </div>
    <div class="entry-content" >
      <b>2. Welke gegevens worden verzameld?</b><br>
      Algemene gegevens met betrekking tot bezoeken aan de website van de Vereniging worden verzameld zonder dat de bezoekers worden geïdentificeerd. Deze gegevens omvatten tevens het IP-adres van de bezoeker. Het IP-adres is een nummer dat automatisch aan de computer wordt toegekend wanneer het internet wordt opgestart. Het IP-adres wordt niet gekoppeld aan de identificeerbare gegevens van de bezoeker, tenzij hiervoor uitdrukkelijk toestemming is verkregen.
      Persoonsgegevens worden door de Vereniging verzameld voor de hieronder genoemde doeleinden. Deze gegevens staan de Vereniging ter beschikking doordat u uw gegevens zelf heeft ingevuld op het betreffende formulier van de Vereniging, dan wel op andere wijze kenbaar heeft gemaakt. Deze gegevens betreffen veelal uw identiteit en contactgegevens (zoals e-mailadres en NAW-gegevens).
      <p></p>
  </div>
  <div class="entry-content" >
    <b>3. Op welke wijze worden de persoonsgegevens gebruikt?</b><br>

De Vereniging verwerkt uw persoonsgegevens voor de volgende doeleinden:
<ul>
  <li>het uitoefenen van de doelstellingen van de Vereniging, inclusief maar niet beperkt tot
    <ol>
      <li>om contact met u te kunnen opnemen;</li>
      <li>om u informatie te sturen;</li>
      <li>het organiseren van schaakwedstrijden;</li>
      <li>het bijhouden van uitslagen en standen;</li>
      <li>het faciliteren en bevorderen van deelname aan schaakwedstrijden.</li>
    </ol>
  </li>
  <li>het bijhouden van een ledenadministratie;</li>
    <li>het berekenen, vastleggen en innen van contributies en/of inschrijfgelden;</li>
    <li>het voldoen aan wettelijke verplichtingen.</li>
    <li>het toegang verlenen tot het (met een gebruikersnaam+wachtwoord) beveiligde deel van de website met de volgende functionaliteit:
      <ol>
        <li>Wedstrijd indelingen maken</li>
          <li>Uitslagen invoeren</li>
            <li>Uitwisseling geclassificeerde informatie</li>
              <li>Inzien/aanpassen van de persoonlijke gegevens en instellingen.        </li>
      </ol>
    </li>
</ul>
<p></p>
</div>

<div class="entry-content" >
  <b>4. Aan wie worden de persoonsgegevens verstrekt?</b><br>
    Uw persoonsgegevens worden verstrekt dan wel gedeeld met de overkoepelende regionale bond en de landelijke bond ("KNSB").
    Daarnaast verstrekt de Vereniging ten behoeve van de ratingverwerking wedstrijduitslagen aan de KNSB.
    <p></p>
    Wilt u geen e-mail meer ontvangen met betrekking tot de activiteiten en nieuwtjes van de Vereniging, dan kunt u dat aangeven bij uw persoonlijke gegevens (Mijn EP) op de website van de vereniging.
  <p></p>
</div>




</div>
</section>
