import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nhsb',
  templateUrl: './nhsb.component.html',
  styleUrls: ['./nhsb.component.less']
})
export class NhsbComponent implements OnInit, OnDestroy {
  urlSafe: SafeResourceUrl;
  private sub: any;

  constructor(public sanitizer: DomSanitizer,
              private titleService: Title,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      if (params.page)
      {
        switch (params.page)
        {
            case 'EersteKlasse':
              this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.nhsb.nl/competitie/groep/649');
              break;
            case 'Viertallen':
              this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.nhsb.nl/competitie/groep/641');
              break;
            case 'Rating':
                this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://ratingviewer.nl/list/121/clubs/090015/rang');
                break;
        }
      }
   });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
