import { CompetitieService } from '../services/competitie.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Competitie } from '../competitie';

@Component({
  selector: 'app-competitie-stand',
  templateUrl: './competitie-stand.component.html',
  styleUrls: ['./competitie-stand.component.less']
})
export class CompetitieStandComponent implements OnInit, OnDestroy  {

  public competitie: Competitie;
  public activeIndex: number;
  private sub: any;

  constructor(private titleService: Title,
              private route: ActivatedRoute,
              private competitieservice: CompetitieService) {
    this.titleService.setTitle('En Passant - Stand - 2021/2022');
  }

  ngOnInit() {
    this.newMethod();
  }
  private async newMethod() {
    await this.SetCompetitie();
    this.competitieservice.notifyEvent.subscribe(async () => {
      await this.SetCompetitie();
    });
  }

  private async SetCompetitie() {
    this.competitie = await this.competitieservice.getCompetitie();
    this.sub = this.route.params.subscribe(params => {
      if (params.roundnumber) {
        this.activeIndex = params.roundnumber;
      }

      else {
        this.activeIndex = this.competitie.GroupReport[0].Ranking.length - 1;
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
