import { BekerwedstrijdenComponent } from './bekerwedstrijden/bekerwedstrijden.component';
import { NhsbComponent } from './nhsb/nhsb.component';
import { WedstrijdentabelComponent } from './wedstrijdentabel/wedstrijdentabel.component';
import { CompetitieSpeeldagComponent } from './competitie-speeldag/competitie-speeldag.component';
import { CompetitieStandComponent } from './competitie-stand/competitie-stand.component';
import { PrivacystatementComponent } from './privacystatement/privacystatement.component';
import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';
import { AgendaComponent } from './agenda/agenda.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArtikelenComponent } from './artikelen/artikelen.component';
import { CompetitieSpelerComponent } from './competitie-speler/competitie-speler.component';
import { MatoptwadComponent } from './matoptwad/matoptwad.component';


const routes: Routes = [
  { path: 'Home', component: HomeComponent },
  { path: 'Agenda', component: AgendaComponent },
  { path: 'Artikelen/:id', component: ArtikelenComponent, data: { IsColumn: false } },
  { path: 'Columns/:id', component: ArtikelenComponent, data: { IsColumn: true } },
  { path: 'Artikelen', component: ArtikelenComponent, data: { IsColumn: false } },
  { path: 'Columns', component: ArtikelenComponent, data: { IsColumn: true } },
  { path: 'Privacystatement', component: PrivacystatementComponent },
  { path: 'Competitiestand/:roundnumber', component: CompetitieStandComponent },
  { path: 'Competitiestand', component: CompetitieStandComponent },
  { path: 'Competitiespeler/:playerid', component: CompetitieSpelerComponent },
  { path: 'Competitiespeeldag/:roundid', component: CompetitieSpeeldagComponent },
  { path: 'Competitiespeeldag', component: CompetitieSpeeldagComponent },
  { path: 'Wedstrijdentabel', component: WedstrijdentabelComponent },
  { path: 'Nhsb/:page', component: NhsbComponent },
  { path: 'Bekerwedstrijden', component: BekerwedstrijdenComponent },
  { path: 'MatOptWad', component: MatoptwadComponent },
  { path: '', redirectTo: '/Home', pathMatch: 'full' }, // redirect to `first-component`
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
