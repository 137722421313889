<ng-template #content let-modal>
  <form *ngIf="articleForm" name="articleForm" [formGroup]="articleForm" novalidate>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Artikel wijzigen</h4>
    </div>
    <div class="modal-body">
      <div class="form-group row">
        <label class="col-form-label col-sm-4" for="date">Datum</label>
        <div class="col-sm-8">
          <div class="input-group">
            <input type="text" id="date" bsDatepicker [bsConfig]="{
                isAnimated: true,
                showWeekNumbers: false
              }" placeholder="" class="form-control form-control-datepicker" formControlName="date" />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-sm-4" for="title">Titel</label>
        <div class="col-sm-8">
          <input type="text" id="title" placeholder="" class="form-control" formControlName="title" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-sm-4" for="author">Auteur</label>
        <div class="col-sm-8">
          <input type="text" id="author" placeholder="" class="form-control" formControlName="author" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-sm-4" for="intro">Intro</label>
        <div class="col-sm-8">
          <textarea id="intro" placeholder="" class="form-control" formControlName="intro"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-sm-4" for="image">Afbeelding</label>
        <div class="col-sm-8">
          <ngp-image-picker [_config]="imagePickerConf" ($imageChanged)="onImageChange($event)"></ngp-image-picker>

          <div class="form-group row">
            <div class="col-sm-8">
              <input type="text" id="imagename" placeholder="afbeelding naam" class="form-control"
                formControlName="imagename" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-sm-4" for="article">Artikel</label>
        <div class="col-sm-8">
          <angular-editor formControlName="article" [config]="editorConfig"></angular-editor>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('Close')">
        Sluiten
      </button>
      <button type="button" class="btn btn-primary" (click)="modal.close('Save')">
        Opslaan
      </button>
    </div>
  </form>
</ng-template>

<section id="blog" class="blog">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 entries" *ngIf="currentarticle == null">
              <div class="row" *ngIf="auth.isAdmin">
                <button type="button" class="btn btn-primary" (click)="open(content, null)">
                  <i class="bx bx-new"></i> Nieuw
                </button>
              </div>

              <div *ngIf="loading">
                <div class="spinner-border" style="width: 3rem; height: 3rem" role="status"></div>
                <span> Laden...</span>
              </div>
              <p></p>
              <article class="entry" *ngFor="let article of Articles">
                <div class="entry-img">
                  <img src="{{ article.ArticleImage }}" alt="" class="img-fluid" style="width: 100%" />
                </div>

                <h2 class="entry-title">
                  <a routerLink="/Artikelen/{{ article.ID }}">{{
                    article.Title
                  }}</a>
                </h2>

                <div class="entry-meta">
                  <ul>
                    <li class="d-flex align-items-center">
                      <i class="icofont-user"></i>
                      <a routerLink="/Artikelen/{{ article.ID }}">{{
                        article.Author
                      }}</a>
                    </li>
                    <li class="d-flex align-items-center">
                      <i class="icofont-wall-clock"></i>
                      <a routerLink="/Artikelen/{{ article.ID }}">{{
                        article.Date | date: "EEEEEE dd LLL yyyy"
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="entry-content">
                  <p>
                    {{ article.ArticleIntro }}
                    <br />
                  </p>
                  <div class="read-more">
                    <a routerLink="/{{BaseRoute}}/{{ article.ID }}">Lees meer</a>
                  </div>
                  <div *ngIf="auth.isAdmin">
                    <button type="button" class="btn btn-primary" (click)="open(content, article)">
                      <i class="bx bx-edit"></i>
                    </button>
                    <button type="button" class="btn btn-danger" (click)="delete(article)">
                      <i class="bx bx-x-circle"></i>
                    </button>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div class="row" *ngIf="currentarticle">
            <div class="col-lg-12 entries">
              <article class="entry">
                <div class="entry-img">
                  <img src="{{ currentarticle.ArticleImage }}" alt="" class="img-fluid" style="width: 100%" />
                </div>

                <h2 class="entry-title">
                  <a routerLink="/Artikelen/{{ currentarticle.ID }}">{{
                    currentarticle.Title
                  }}</a>
                </h2>

                <div class="entry-meta">
                  <ul>
                    <li class="d-flex align-items-center">
                      <i class="icofont-user"></i>
                      <a routerLink="/Artikelen/{{ currentarticle.ID }}">{{
                        currentarticle.Author
                      }}</a>
                    </li>
                    <li class="d-flex align-items-center">
                      <i class="icofont-wall-clock"></i>
                      <a routerLink="/Artikelen/{{ currentarticle.ID }}">{{
                        currentarticle.Date | date: "EEEEEE dd LLL yyyy"
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="entry-content">
                  <div [innerHTML]="currentarticle.ArticleText | sanitizeHtml"></div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="list-group">
          <a *ngFor="let article of ArticleList" routerLink="/{{BaseRoute}}/{{ article.ID }}" class="list-group-item list-group-item-action">
            <b>{{article.Date | date: "EEEEEE dd LLL yyyy"}} -
                {{ article.Author }}</b><br>
                <span class="text-success"><u>{{ article.Title }}</u></span>
        </a>
        </div>
      </div>
    </div>
  </div>
</section>
