import { Ranking, Ab, Competitie } from '../competitie';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Player, History } from 'src/app/competitie';
import { CompetitieService } from '../services/competitie.service';

export interface RoundPlayerInfo {
  Datum: string;
  Ronde: string;
  Wit: string;
  Zwart: string;
  Resultaat: string;
}

@Component({
  selector: 'app-competitie-speler',
  templateUrl: './competitie-speler.component.html',
  styleUrls: ['./competitie-speler.component.less']
})
export class CompetitieSpelerComponent implements OnInit, OnDestroy {


  private sub: any;
  public player: Player;
  public competitie: Competitie;

  constructor(private titleService: Title,
              private route: ActivatedRoute,
              private competitieservice: CompetitieService) {

  }

  ngOnInit() {
    this.newMethod();
  }
  private async newMethod() {
    await this.SetCompetitie();
    this.competitieservice.notifyEvent.subscribe(async () => {
      await this.SetCompetitie();
    });
  }

  private async SetCompetitie() {
    this.competitie = await this.competitieservice.getCompetitie();
    this.sub = this.route.params.subscribe(params => {
      if (params.playerid) {
        this.player = this.competitie.GroupReport[0].Players.Player.find(p => p.ID === parseInt(params.playerid, 0));
        this.titleService.setTitle('En Passant - ' + this.player.Name);
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  public getRound(ranking: Ranking): RoundPlayerInfo {
    // tslint:disable-next-line:max-line-length
    const playerhist = this.competitie.GroupReport[0].History[this.competitie.GroupReport[0].History.length - 1].Player.find(p => p.ID === this.player.ID);

    const roundresult = playerhist.Game.find(g => g.Round === ranking.Round);
    const roundname = this.competitie.GroupReport[0].Ranking.find(r => r.Round === ranking.Round);
    if (roundresult) {
      let score;
      switch (roundresult.Res) {
        case 1:
          score = '1 - 0';
          break;
        case 2:
          score = '½ - ½';
          break;
        case 3:
          score = '0 - 1';
          break;
      }
      return { Wit: roundresult.White, Zwart: roundresult.Black, Resultaat: score, Ronde: roundname.Name, Datum: ranking.Date };
    }
    else {
      const abs = playerhist.Abs.find(g => g.Round === ranking.Round);
      let absmessage;
      switch (abs.Reason) {
        case 0:
          absmessage = 'Afwezig';
          break;
        case 1:
          absmessage = 'Afgemeld';
          break;
        case 16:
        case 5:
          absmessage = 'Over';
          break;
      }
      return { Wit: abs.Player, Zwart: '', Resultaat: absmessage, Ronde: roundname.Name, Datum: ranking.Date };
    }
  }
  public getRankings(): RoundPlayerInfo[] {
    const result: RoundPlayerInfo[] = [];
    this.competitie.GroupReport[0].Ranking.forEach(ranking => {
      result.push(this.getRound(ranking));
    });

    return result;
  }
}

