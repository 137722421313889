/* Options:
Date: 2021-08-12 08:16:46
Version: 5.92
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost/EnPassant/api

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    SessionId: string;
}

export interface IHasBearerToken
{
    BearerToken: string;
}

export interface IPost
{
}

// @DataContract
export class Agenda implements IBusinessEntity
{
    // @DataMember
    public ID: number;

    // @DataMember
    public EventDate: string;

    // @DataMember
    public Theme: string;

    // @DataMember
    public Location: string;

    // @DataMember
    public Description: string;

    public constructor(init?: Partial<Agenda>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ArticleItem implements IBusinessEntity
{
    // @DataMember
    public ID: number;

    // @DataMember
    public Date: string;

    // @DataMember
    public Author: string;

    // @DataMember
    public Title: string;

    public constructor(init?: Partial<ArticleItem>) { (Object as any).assign(this, init); }
}

export interface IBusinessEntity
{
    ID: number;
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public ErrorCode: string;

    // @DataMember(Order=2)
    public FieldName: string;

    // @DataMember(Order=3)
    public Message: string;

    // @DataMember(Order=4)
    public Meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public ErrorCode: string;

    // @DataMember(Order=2)
    public Message: string;

    // @DataMember(Order=3)
    public StackTrace: string;

    // @DataMember(Order=4)
    public Errors: ResponseError[];

    // @DataMember(Order=5)
    public Meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Article implements IBusinessEntity
{
    // @DataMember
    public ID: number;

    // @DataMember
    public Date: string;

    // @DataMember
    public Author: string;

    // @DataMember
    public Title: string;

    // @DataMember
    public ArticleText: string;

    // @DataMember
    public ArticleIntro: string;

    // @DataMember
    public ArticleImage: string;

    // @DataMember
    public IsColumn: boolean;

    public constructor(init?: Partial<Article>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public UserId: string;

    // @DataMember(Order=2)
    public SessionId: string;

    // @DataMember(Order=3)
    public UserName: string;

    // @DataMember(Order=4)
    public DisplayName: string;

    // @DataMember(Order=5)
    public ReferrerUrl: string;

    // @DataMember(Order=6)
    public BearerToken: string;

    // @DataMember(Order=7)
    public RefreshToken: string;

    // @DataMember(Order=8)
    public ProfileUrl: string;

    // @DataMember(Order=9)
    public Roles: string[];

    // @DataMember(Order=10)
    public Permissions: string[];

    // @DataMember(Order=11)
    public ResponseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public Meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

/**
* 
*/
// @Route("/FetchAllAgendaItems", "POST")
// @Api(Description="")
// @DataContract
export class FetchAllAgendaItemsRequest implements IReturn<Agenda[]>
{

    public constructor(init?: Partial<FetchAllAgendaItemsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<Agenda>(); }
    public getTypeName() { return 'FetchAllAgendaItemsRequest'; }
}

/**
* 
*/
// @Route("/UpdateAgendaItem", "POST")
// @Api(Description="")
// @DataContract
export class UpdateAgendaItemRequest implements IReturn<boolean>
{
    // @DataMember
    public Agenda: Agenda;

    public constructor(init?: Partial<UpdateAgendaItemRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'UpdateAgendaItemRequest'; }
}

/**
* 
*/
// @Route("/DeleteAgendaItem", "POST")
// @Api(Description="")
// @DataContract
export class DeleteAgendaItemRequest implements IReturn<boolean>
{
    // @DataMember
    public Agenda: Agenda;

    public constructor(init?: Partial<DeleteAgendaItemRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'DeleteAgendaItemRequest'; }
}

/**
* 
*/
// @Route("/FetchAllArticleItems", "POST")
// @Api(Description="")
// @DataContract
export class FetchAllArticleItemsRequest implements IReturn<Article[]>
{
    // @DataMember
    public Page: number;

    // @DataMember
    public IsColumn: boolean;

    public constructor(init?: Partial<FetchAllArticleItemsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<Article>(); }
    public getTypeName() { return 'FetchAllArticleItemsRequest'; }
}

/**
* 
*/
// @Route("/FetchAllArticleList", "POST")
// @Api(Description="")
// @DataContract
export class FetchAllArticleListRequest implements IReturn<ArticleItem[]>
{
    // @DataMember
    public IsColumn: boolean;

    public constructor(init?: Partial<FetchAllArticleListRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ArticleItem>(); }
    public getTypeName() { return 'FetchAllArticleListRequest'; }
}

/**
* 
*/
// @Route("/FetchAllArticleById", "POST")
// @Api(Description="")
// @DataContract
export class FetchAllArticleByIdRequest implements IReturn<Article>
{
    // @DataMember
    public Id: number;

    public constructor(init?: Partial<FetchAllArticleByIdRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Article(); }
    public getTypeName() { return 'FetchAllArticleByIdRequest'; }
}

/**
* 
*/
// @Route("/UpdateArticleItem", "POST")
// @Api(Description="")
// @DataContract
export class UpdateArticleItemRequest implements IReturn<boolean>
{
    // @DataMember
    public Article: Article;

    // @DataMember
    public ImageBase64: string;

    // @DataMember
    public ImageName: string;

    public constructor(init?: Partial<UpdateArticleItemRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'UpdateArticleItemRequest'; }
}

/**
* 
*/
// @Route("/DeleteArticleItem", "POST")
// @Api(Description="")
// @DataContract
export class DeleteArticleItemRequest implements IReturn<boolean>
{
    // @DataMember
    public Article: Article;

    public constructor(init?: Partial<DeleteArticleItemRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'DeleteArticleItemRequest'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public State: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public UserName: string;

    // @DataMember(Order=6)
    public Password: string;

    // @DataMember(Order=7)
    public RememberMe?: boolean;

    // @DataMember(Order=9)
    public ErrorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public UseTokenCookie?: boolean;

    // @DataMember(Order=17)
    public AccessToken: string;

    // @DataMember(Order=18)
    public AccessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public Meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

