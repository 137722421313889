import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import { Competitie } from '../competitie';

@Injectable({
  providedIn: 'root'
})
export class CompetitieService {
  @Output() notifyEvent = new EventEmitter();
  constructor(private http: HttpClient) { }

  private year: string;
  public async getCompetitie(): Promise<Competitie> {
    let file = '../../assets/data/competitieintern.json';
    if (this.year)
    {
      file = '../../assets/data/competitieintern-' + this.year + '.json';
    }
    return await this.http.get<Competitie>(file).toPromise();
  }

  public notify()
  {
    this.year = localStorage.getItem('currentyear');
    this.notifyEvent.emit();
  }
}
