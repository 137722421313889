import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacystatement',
  templateUrl: './privacystatement.component.html',
  styleUrls: ['./privacystatement.component.less']
})
export class PrivacystatementComponent implements OnInit {

  public constructor(private titleService: Title ) { }


  ngOnInit(): void {
    this.titleService.setTitle('Privacy Statement van de Schaakclub En Passant te Den Burg, Texel');
  }

}
