import { Injectable } from '@angular/core';
import { JsonServiceClient } from '@servicestack/client';
import { BehaviorSubject } from 'rxjs';
import { AuthserviceService } from './authservice.service';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable({
  providedIn: 'root'
})
export class ApiClientProviderService {

  //  public baseUrl = 'https://localhost/EnPassant/api/';
  public baseUrl = 'https://services.enpassant-texel.nl/api/';
  private enpassantApiClient: JsonServiceClient;
  private isReadySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isReadyAuthSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isReady$ = this.isReadySubject.asObservable();
  public isReadyAuth$ = this.isReadyAuthSubject.asObservable();
  public usernameAuth0: string;
  constructor(private auth: AuthserviceService) { this.createEnPassantApiClient(); }

  private createEnPassantApiClient() {
    this.enpassantApiClient = new JsonServiceClient(this.baseUrl);
    this.enpassantApiClient.exceptionFilter = (res: Response, ex: any) => {
      // API_COMMUNICATION_FAILED
    };
  }
  setAuth0() {
    if (localStorage.getItem('access_token') != null) {
      this.enpassantApiClient.bearerToken = localStorage.getItem('access_token');
    }
  }


  get enpassantApi() {
    this.setAuth0();
    return this.enpassantApiClient;
  }
}
