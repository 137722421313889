<section   *ngIf="competitie">
  <div class="container">
    <div class="row">

      <div class="col-md-10 table-responsive">
        <h2>{{player.Name}}</h2>
        <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" width="10%">Datum</th>
            <th scope="col" width="20%">Ronde</th>
            <th scope="col" width="30%">Wit</th>
            <th scope="col" width="30%">Zwart</th>
            <th scope="col" width="10%" class="text-center">Uitslag</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ranking of getRankings()">
              <td>{{ ranking.Datum }}</td>
              <td>{{ ranking.Ronde }}</td>
              <td [ngClass]="{'font-weight-bold': player.Name === ranking.Wit }">{{ ranking.Wit }}</td>
              <td [ngClass]="{'font-weight-bold': player.Name === ranking.Zwart }">{{ ranking.Zwart }}</td>
              <td class="text-center">{{ ranking.Resultaat }}</td>
          </tr>
        </tbody>
      </table>
      </div>
      <div class="col-sm-2">
        <h3>Spelers</h3>
        <u class="list-group">
          <li class="list-group-item list-group-item-action" *ngFor="let player of competitie.GroupReport[0].Players.Player"><a class="" routerLink='/Competitiespeler/{{player.ID}}'>{{player.Name}}</a></li>
        </u>
      </div>
    </div>
    <div class="row pt-3 pb-3">
      <app-yearselector></app-yearselector>
    </div>
  </div>
</section>
