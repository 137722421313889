import { Competitie, RoundHist } from '../competitie';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { Ranking, Player, History } from 'src/app/competitie';
import { CompetitieService } from '../services/competitie.service';

export interface RoundGameInfo {
  Wit: string;
  WitId: number;
  Zwart: string;
  ZwartId: number;
  Resultaat: string;
}

@Component({
  selector: 'app-competitie-speeldag',
  templateUrl: './competitie-speeldag.component.html',
  styleUrls: ['./competitie-speeldag.component.less']
})
export class CompetitieSpeeldagComponent implements OnInit, OnDestroy {

  private sub: any;
  public round: RoundHist;
  public competitie: Competitie;

  constructor(private titleService: Title,
              private route: ActivatedRoute,
              private competitieservice: CompetitieService) {
  }
  public getRound(): RoundGameInfo[] {
    if (!this.round)
    {
      return null;
    }
    const result: RoundGameInfo[] = [];
    this.round.Game.forEach(game => {


      let score;
      switch (game.Res) {
        case 1:
          score = '1 - 0';
          break;
        case 2:
          score = '½ - ½';
          break;
        case 3:
          score = '0 - 1';
          break;
      }
      result.push(
        { Wit: game.White, WitId: game.WhiteNr, Zwart: game.Black, ZwartId: game.BlackNr, Resultaat: score }
      );
    });

    this.round.Abs.forEach(abs => {
      let absmessage;
      switch (abs.Reason)
        {
          case 0:
            absmessage = 'Afwezig';
            break;
          case 1:
            absmessage = 'Afgemeld';
            break;
          case 16:
          case 5:
            absmessage = 'Over';
            break;
        }
      result.push(
          { Wit: abs.Player, WitId: abs.PlayerNr, Zwart: '', ZwartId: 0, Resultaat: absmessage }
        );
    });

    return result;
  }
  ngOnInit() {
    this.initSpeeldag();
  }

  async initSpeeldag() {
    await this.SetCompetitie();
    this.competitieservice.notifyEvent.subscribe(async () => {
      await this.SetCompetitie();
    });
  }

  private async SetCompetitie() {
    this.competitie = await this.competitieservice.getCompetitie();
    this.sub = this.route.params.subscribe(params => {
      let roundId: number;
      if (params.roundid) {
        roundId = parseInt(params.roundid, 0);
      }
      else {
        roundId = this.competitie.GroupReport[0].RoundHist[this.competitie.GroupReport[0].RoundHist.length - 1].ID;
      }

      this.round = this.competitie.GroupReport[0].RoundHist.find(p => p.ID === roundId);
      this.titleService.setTitle('En Passant - uitslagen');

    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
