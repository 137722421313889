import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Agenda, DeleteAgendaItemRequest, FetchAllAgendaItemsRequest, UpdateAgendaItemRequest } from 'enpassantapi.dtos';
import { ApiClientProviderService } from '../services/api.client.provider.service';
import { AuthserviceService } from '../services/authservice.service';
import dayjs from 'dayjs';
import { FormBuilderTyped, FormGroupTyped } from '../services/form-builder-typed';
import { FormControl } from '@angular/forms';



interface IAgenda
{
  eventdate: Date;
  eventtime: Date;
  thema: string;
  locatie: string;
  opmerkingen: string;
}

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.less']
})
export class AgendaComponent implements OnInit {

  Agenda: Agenda[];
  editItem: Agenda;
  closeResult: string;
  public loading: boolean;

  agendaForm: FormGroupTyped<IAgenda>;

  public constructor(private titleService: Title,
                     private  apiservice: ApiClientProviderService,
                     public auth: AuthserviceService,
                     private formBuilderTyped: FormBuilderTyped,
                     private modalService: NgbModal,
                     private cdr: ChangeDetectorRef) { }


  ngOnInit() {
    this.initAgenda();
  }

  async initAgenda()
  {
    this.titleService.setTitle('Agenda');
    this.loading = true;
    try {
      if (sessionStorage.getItem('epagenda') === null)
      {
        sessionStorage.setItem('epagenda', JSON.stringify(await this.apiservice.enpassantApi.post(new FetchAllAgendaItemsRequest())));
      }
      this.Agenda = JSON.parse(sessionStorage.getItem('epagenda'));
    }
    finally {
      this.loading = false;
      this.cdr.detectChanges();
    }
  }
  async open(content, item: Agenda) {
    this.editItem = item;
    if (item === null)
    {
      this.editItem = new Agenda();
    }

    this.agendaForm = this.formBuilderTyped.group<IAgenda>({
      eventdate: new FormControl(dayjs(this.editItem.EventDate).toDate()),
      eventtime: new FormControl(dayjs(this.editItem.EventDate).toDate()),
      thema: new FormControl(this.editItem.Theme),
      locatie: new FormControl(this.editItem.Location),
      opmerkingen: new FormControl(this.editItem.Description)
    });
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then(async (result) => {
      this.closeResult = result;
      if (result === 'Save')
      {
        this.submitAgenda();
      }
    }, (reason) => {
      this.closeResult = reason;
    });
  }

  async delete(item: Agenda)
  {
    const request = new DeleteAgendaItemRequest();
    request.Agenda = item;
    await this.apiservice.enpassantApi.post(request);
    this.Agenda = await this.apiservice.enpassantApi.post(new FetchAllAgendaItemsRequest());
    this.cdr.detectChanges();

  }
 async submitAgenda()
  {
    const request = new UpdateAgendaItemRequest();
    this.editItem.Description = this.agendaForm.controls.opmerkingen.value;
    this.editItem.Theme = this.agendaForm.controls.thema.value;
    this.editItem.Location = this.agendaForm.controls.locatie.value;
    // tslint:disable-next-line:max-line-length
    this.editItem.EventDate = (dayjs(this.agendaForm.controls.eventdate.value)).format('YYYY-MM-DD') + ' ' +  (dayjs(this.agendaForm.controls.eventtime.value)).format('h:mm A');
    request.Agenda = this.editItem;
    await this.apiservice.enpassantApi.post(request);
    this.Agenda = await this.apiservice.enpassantApi.post(new FetchAllAgendaItemsRequest());
    this.cdr.detectChanges();
  }
}
